<template>
	<div class="infectiousDiseaseData">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item prop="provinceDictionaryItem" label="省：">
					<el-select v-model="formInline.provinceDictionaryItem" placeholder="请选择省" @change="changeEconomize($event, 1)">
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="cityDictionaryItem" label="市：">
					<el-select v-model="formInline.cityDictionaryItem" placeholder="请选择市" @change="changeEconomize($event, 2)">
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="regionCode" label="区县：">
					<el-select v-model="formInline.regionCode" placeholder="请选择" @change="changeEconomize($event, 3)">
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="townCode" label="乡镇：">
					<el-select v-model="formInline.townCode" placeholder="请选择" @change="changeEconomize($event, 4)">
						<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="villageCode" label="村：">
					<el-select v-model="formInline.villageCode" placeholder="请选择" @change="changeEconomize($event, 5)">
						<el-option v-for="item in villageList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getData" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="container">
			<div class="infectiousDiseaseData-title">
				<div class="infectiousDiseaseData-title-content">
					<div class="institutionTotal">
						<img src="@/assets/imgs/peoples.png" alt="" />
						<div class="institutionTotal-content">
							<p>传染病患者采集人数</p>
							<p>{{ datas.totalNum || 0 }}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="content">
				<el-row :gutter="12">
					<el-col :span="12">
						<div class="chartsClass" ref="infectiousDiseaseHeight">
							<p>
								<img src="@/assets/imgs/datas.png" alt="" />
								传染病各类总数
							</p>
							<div id="infectiousDisease" :style="{ width: '100%', height: infectiousDiseaseHeight + 'px' }"></div>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="chartsClass" ref="hospitalChartsHeight">
							<p>
								<img src="@/assets/imgs/datas.png" alt="" />
								传染病各类占比
							</p>
							<div id="hospitalCharts" :style="{ width: '100%', height: hospitalChartsHeight + 'px' }"></div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
import 'echarts-liquidfill';
import * as echarts from 'echarts';
export default {
	name: 'infectiousDiseaseData',
	data() {
		return {
			formInline: {
				provinceDictionaryItem: null,
				cityDictionaryItem: null,
				regionCode: null,
				townCode: null,
				villageCode: null,
			},
			townList: [], //乡镇
			villageList: [], //村
			districtList: [], //区县
			marketList: [], //市
			economizeList: [], //省
			infectiousDiseaseHeight: 500, // 传染病各类总数
			hospitalChartsHeight: 500, // 传染病各类占比
			regionCode: null,
			datas: {},
			myChart1: null,
			myChart2: null,
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.infectiousDiseaseHeight =
				this.$refs.infectiousDiseaseHeight.offsetHeight - 90 > 500 ? this.$refs.infectiousDiseaseHeight.offsetHeight - 90 : 500;
			this.hospitalChartsHeight = this.$refs.hospitalChartsHeight.offsetHeight - 90 > 500 ? this.$refs.hospitalChartsHeight.offsetHeight - 90 : 500;
		});
		this.getEconomizeList();
		this.getData();
	},
	methods: {
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api.getChinaRegions)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: null,
						});
					}
				})
				.catch((e) => {});
		},
		// 获取市/区县
		changeEconomize(data, type) {
			this.regionCode = data;
			switch (type) {
				case 1:
					this.formInline.cityDictionaryItem = null;
					this.formInline.regionCode = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.marketList = [];
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.marketList = res.data.collection || [];
								this.marketList.unshift({
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				case 2:
					this.formInline.regionCode = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.districtList = res.data.collection || [];
								this.districtList.unshift({
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				case 3:
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.townList = res.data.collection || [];
								this.townList.unshift({
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				case 4:
					this.formInline.villageCode = null;
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.villageList = res.data.collection || [];
								this.villageList.unshift({
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				default:
					break;
			}
		},
		// 查询条件
		getData() {
			let regionCode = this.formInline.villageCode ? this.formInline.villageCode : this.regionCode;
			this.$http
				.get(this.api.getTalentInfectiousDiseases, { params: { regionCode } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.datas = res.data.collection[0] || {};
						const { bnum, cnum, anum, arate, crate, brate } = this.datas;
						let data1 = [
							{
								name: '甲类',
								value: anum,
							},
							{
								name: '乙类',
								value: bnum,
							},
							{
								name: '丙类',
								value: cnum,
							},
						];
						let data2 = [
							{
								name: `甲类占比 ${arate != 0 ? (arate * 100).toFixed(2) : 0}%`,
								value: anum,
							},
							{
								name: `乙类占比 ${brate != 0 ? (brate * 100).toFixed(2) : 0}%`,
								value: bnum,
							},
							{
								name: `丙类占比 ${crate != 0 ? (crate * 100).toFixed(2) : 0}%`,
								value: cnum,
							},
						];
						this.getinfectiousDiseaseData(data1);
						this.getHospitalChartsData(data2);
					}
				})
				.catch((e) => {});
		},
		// 获取传染病各类总数
		getinfectiousDiseaseData(data) {
			this.myChart1 = echarts.init(document.getElementById('infectiousDisease'));
			var dataStyle = {
				normal: {
					label: {
						show: false,
					},
					labelLine: {
						show: false,
					},
					shadowBlur: 40,
					borderWidth: 10,
					shadowColor: 'rgba(0, 0, 0, 0)', //边框阴影
				},
			};
			var placeHolderStyle = {
				normal: {
					color: '#F7F8FA',
					label: {
						show: false,
					},
					labelLine: {
						show: false,
					},
				},
				emphasis: {
					color: '#F7F8FA',
				},
			};
			this.myChart1.setOption({
				backgroundColor: '#fff',
				title: {
					text: '{name|' + '共计(人数)' + '}\n{val|' + this.datas.totalNum + '}',
					textStyle: {
						color: '#333333',
						rich: {
							name: {
								fontSize: 12,
								fontWeight: 'normal',
								color: '#666666',
								padding: [10, 0],
								align: 'center',
							},
							val: {
								fontSize: 32,
								fontWeight: 'bold',
								color: '#333333',
								align: 'center',
							},
						},
					},
					textAlign: 'center',
					x: '23.5%',
					y: 'center',
				},
				tooltip: {
					trigger: 'item',
					show: true,
					formatter: '{b} : {d}人',
					backgroundColor: 'rgba(0,0,0,0.7)', // 背景
					padding: [8, 10], //内边距
					extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);', //添加阴影
				},
				legend: {
					backgroundColor: '#F9FAFC',
					padding: [25, 32],
					width: 300,
					type: 'plain',
					icon: 'circle',
					orient: 'vertical',
					left: '55%',
					top: 'center',
					align: 'left',
					itemGap: 15,
					itemWidth: 10, // 设置宽度
					itemHeight: 10, // 设置高度
					symbolKeepAspect: false,
					textStyle: {
						color: '#333',
						rich: {
							name: {
								verticalAlign: 'right',
								align: 'left',
								width: 120,
								fontSize: 12,
							},
							value: {
								align: 'right',
								width: 60,
								fontSize: 12,
							},
						},
					},
					data: data.map((item) => item.name),
					formatter: function (name) {
						if (data && data.length) {
							for (var i = 0; i < data.length; i++) {
								if (name === data[i].name) {
									return '{name| ' + name + '}' + '{value| ' + data[i].value + '(人)}';
								}
							}
						}
					},
				},
				series: [
					{
						name: 'Line 1',
						type: 'pie',
						roundCap: true,
						clockWise: false,
						radius: ['50%', '55%'],
						center: ['24%', '50%'],
						itemStyle: dataStyle,
						hoverAnimation: false,
						startAngle: 90,
						label: {
							borderRadius: 10,
						},
						data: [
							{
								value: data[0].value != 0 ? (data[0].value / this.datas.totalNum) * 100 : 0,
								name: '甲类',
								itemStyle:
									data[0].value != 0
										? {
												normal: {
													color: '#165DFF',
												},
										  }
										: placeHolderStyle,
							},
							{
								value: 100 - (data[0].value / this.datas.totalNum) * 100,
								name: '',
								tooltip: {
									show: false,
								},
								itemStyle: placeHolderStyle,
							},
						],
					},
					{
						name: 'Line 2',
						type: 'pie',
						roundCap: true,
						clockWise: false,
						radius: ['30%', '35%'],
						center: ['24%', '50%'],
						itemStyle: dataStyle,
						hoverAnimation: false,
						startAngle: 90,
						data: [
							{
								value: data[1].value != 0 ? (data[1].value / this.datas.totalNum) * 100 : 0,
								name: '乙类',
								itemStyle:
									data[1].value != 0
										? {
												normal: {
													color: '#F7BA1E',
												},
										  }
										: placeHolderStyle,
							},
							{
								value: 100 - (data[1].value / this.datas.totalNum) * 100,
								name: '',
								tooltip: {
									show: false,
								},
								itemStyle: placeHolderStyle,
							},
						],
					},
					{
						name: 'Line 3',
						type: 'pie',
						roundCap: true,
						clockWise: false,
						radius: ['40%', '45%'],
						center: ['24%', '50%'],
						itemStyle: dataStyle,
						hoverAnimation: false,
						startAngle: 90,
						data: [
							{
								value: data[2].value != 0 ? (data[2].value / this.datas.totalNum) * 100 : 0,
								name: '丙类',
								itemStyle:
									data[2].value != 0
										? {
												normal: {
													color: '#02CADF',
												},
										  }
										: placeHolderStyle,
							},
							{
								value: 100 - (data[2].value / this.datas.totalNum) * 100,
								name: '',
								tooltip: {
									show: false,
								},
								itemStyle: placeHolderStyle,
							},
						],
					},
				],
			});
			window.addEventListener('resize', () => {
				this.myChart1.resize();
			});
		},
		// 获取传染病各类占比
		getHospitalChartsData(data) {
			this.myChart2 = echarts.init(document.getElementById('hospitalCharts'));
			let title = '共计(人数)';
			this.myChart2.setOption({
				title: {
					text: '{name|' + title + '}\n{val|' + this.datas.totalNum + '}',
					textAlign: 'center',
					x: '23.5%',
					y: 'center',
					textStyle: {
						color: '#333333',
						rich: {
							name: {
								fontSize: 12,
								fontWeight: 'normal',
								color: '#666666',
								padding: [10, 0],
								align: 'center',
							},
							val: {
								fontSize: 32,
								fontWeight: 'bold',
								color: '#333333',
								align: 'center',
							},
						},
					},
				},
				tooltip: {
					trigger: 'item',
					show: true,
					// formatter: '{b} : <br/>{d}%',
					// backgroundColor: 'rgba(0,0,0,0.7)', // 背景
					// padding: [8, 10], //内边距
					// extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);', //添加阴影
				},
				color: ['#165DFF', '#04C59F', '#F7BA1E', '#02CADF'],
				legend: {
					backgroundColor: '#F9FAFC',
					padding: [25, 32],
					width: 300,
					type: 'plain',
					icon: 'circle',
					orient: 'vertical',
					left: '55%',
					top: 'center',
					align: 'left',
					itemGap: 15,
					itemWidth: 10, // 设置宽度
					itemHeight: 10, // 设置高度
					symbolKeepAspect: false,
					textStyle: {
						color: '#333',
						rich: {
							name: {
								verticalAlign: 'right',
								align: 'left',
								width: 120,
								fontSize: 12,
							},
							value: {
								align: 'right',
								width: 60,
								fontSize: 12,
							},
						},
					},
					data: data.map((item) => item.name),
					formatter: function (name) {
						if (data && data.length) {
							for (var i = 0; i < data.length; i++) {
								if (name === data[i].name) {
									return '{name| ' + name + '}' + '{value| ' + data[i].value + '(人)}';
								}
							}
						}
					},
				},
				series: [
					{
						name: '数量',
						type: 'pie',
						radius: ['40%', '52%'],
						center: ['24%', '50%'],
						data: data,
						label: {
							normal: {
								show: false,
								position: 'center',
								formatter: '{text|{c}}\n{b}',
								rich: {
									text: {
										align: 'center',
										verticalAlign: 'middle',
										padding: 8,
										fontSize: 30,
									},
									value: {
										align: 'center',
										verticalAlign: 'middle',
										fontSize: 20,
									},
								},
							},
							emphasis: {
								show: false,
							},
						},
						labelLine: {
							normal: {
								show: false,
							},
						},
					},
				],
			});
			window.addEventListener('resize', () => {
				this.myChart2.resize();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.infectiousDiseaseData {
	.container {
		margin: 16px;
	}
	.demo-form-inline {
		/deep/ .el-select {
			width: 106px !important;
			.el-input {
				width: 106px !important;
				.el-input__inner {
					width: 106px !important;
				}
			}
		}
		/deep/ .el-input {
			width: 106px;
		}
	}
	&-title {
		padding: 16px;
		background-color: #fff;
		border-radius: 4px;
		margin-bottom: 12px;
		&-content {
			.institutionTotal {
				display: flex;
				align-items: center;
				width: 100%;
				margin-right: 12px;
				background: #f9fafc;
				border-radius: 4px;
				padding: 16px;
				img {
					margin-right: 16px;
				}
				&-content {
					p {
						color: #999;
						font-size: 14px;
						height: 14px;
						line-height: 14px;
						&:last-child {
							font-size: 24px;
							line-height: 24px;
							height: 24px;
							margin-top: 12px;
							color: #333;
						}
					}
				}
			}
		}
	}
	.content {
		.chartsClass {
			background-color: #fff;
			margin-bottom: 12px;
			padding: 20px 16px;
			border-radius: 4px;
			box-shadow: 0px 6px 48px 0px rgba(81, 81, 81, 0.04);
			&:last-child {
				margin-bottom: 0;
			}
			p {
				font-size: 16px;
				line-height: 28px;
				font-weight: 500;
				color: #333333;
				margin-bottom: 22px;
				display: flex;
				align-items: center;
				img {
					margin-right: 12px;
				}
			}
			.chineseMedical {
				width: calc(50% - 30px);
				height: 100%;
				margin-right: 60px;
			}
			.legendClass {
				width: 100%;
				max-width: 300px;
				margin: 0 auto;
				padding: 24px 32px;
				background-color: #f9fafc;
				border-radius: 4px;
				&-content {
					display: flex;
					justify-content: space-between;
					align-items: center;
					&:first-child {
						margin-bottom: 20px;
					}
					> p {
						font-size: 16px;
						line-height: 16px;
						height: 16px;
						color: #333;
						margin-bottom: 0 !important;
						span {
							font-size: 12px;
							color: #999;
						}
					}
					.legendClass-title {
						font-size: 12px !important;
						line-height: 12px;
						height: 12px;
						margin-bottom: 0;
						span {
							display: inline-block;
							width: 14px;
							height: 14px;
							border-radius: 50%;
							border: 1px solid #165dff;
							margin-right: 8px;
						}
					}
					&:last-child {
						.legendClass-title {
							span {
								background-color: #165dff;
							}
						}
					}
				}
			}
		}
	}
	.mr0 {
		margin-right: 0 !important;
	}
}
</style>